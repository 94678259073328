<template>
  <div class="py-3 px-4">
    <div class="flex items-center mb-1">
      <h4 class="text-block-title flex items-center m-0 w-full error">
        <div v-if="isAttributeTypeCheckbox" class="checkbox-wrap">
          <input
            v-model="editedValue"
            class="checkbox"
            type="checkbox"
            @change="onUpdate"
          />
        </div>
        <div class="flex items-center justify-between w-full">
          <span
            class="attribute-name"
            :class="{ error: v$.editedValue.$error }"
          >
            {{ label }}
          </span>
        </div>
      </h4>
    </div>
    <div v-if="isAttributeBoolean">
      <span v-if="value">
        Да
      </span>
      <span v-else>
        Нет
      </span>
    </div>

    <div v-if="isAttributeTypeString">
      <div
        v-tooltip="$t('CUSTOM_ATTRIBUTES.ACTIONS.COPY')"
        @click="onCopy(value)"
      >
        {{ this.value }}
      </div>
    </div>

    <div v-if="isAttributeTypeNumber">
      <div
        v-tooltip="$t('CUSTOM_ATTRIBUTES.ACTIONS.COPY')"
        @click="onCopy(value)"
      >
        {{ this.value }}
      </div>
    </div>

    <div v-if="isAttributeTypeDate">
      <div
        v-tooltip="$t('CUSTOM_ATTRIBUTES.ACTIONS.COPY')"
        @click="onCopy(value)"
      >
        {{ this.displayValue }}
      </div>
    </div>

    <a
      v-if="isAttributeTypeLink"
      :href="value"
      target="_blank"
      rel="noopener noreferrer"
    >
      Ссылка
      <div class="flex max-w-[2rem] gap-1 ml-1 rtl:mr-1 rtl:ml-0">
        <woot-button
          v-if="showActions"
          v-tooltip="$t('CUSTOM_ATTRIBUTES.ACTIONS.COPY')"
          variant="link"
          size="small"
          color-scheme="secondary"
          icon="clipboard"
          class-names="edit-button"
          @click="onCopy"
        />
      </div>
    </a>

    <div v-if="isAttributeArray">
      <div v-for="(children, key) in arrayOptions">
        <p>#: {{ key + 1 }}</p>
        <div>
          <BlancCustomAttributeChildren
            v-for="item in children"
            :key="item.id"
            :name="item.id"
            :attribute-key="item.attribute_key"
            :attribute-type="item.attribute_display_type"
            :label="item.attribute_display_name"
            :icon="item.icon"
            emoji=""
            :value="item.value"
            :show-actions="true"
            :class="attributeClass"
            @update="onUpdate"
            @delete="onDelete"
            @copy="onCopy"
          />
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'; // Импортируем функцию для асинхронного компонента
import { format, parseISO } from 'date-fns';
import { required, url } from '@vuelidate/validators';
import { BUS_EVENTS } from 'shared/constants/busEvents';

import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';

import { isValidURL } from '../../helper/URLHelper';
import { useVuelidate } from '@vuelidate/core';
import th from '../../i18n/locale/th';
import { emitter } from 'shared/helpers/mitt';

const BlancCustomAttributeChildren = defineAsyncComponent(() =>
    import('./BlancCustomAttribute.vue') // Динамический импорт
);

const DATE_FORMAT = 'yyyy-MM-dd';

export default {
  components: {
    MultiselectDropdown,
    BlancCustomAttributeChildren,
  },
  props: {
    label: { type: String, required: true },
    values: { type: Array, default: () => [] },
    value: { type: [String, Number, Boolean, Array], default: '' },
    showActions: { type: Boolean, default: false },
    attributeType: { type: String, default: 'text' },
    attributeKey: { type: String, required: true },
    contactId: { type: Number, default: null },
    attributeClass: {
      type: String,
      default: '',
    },
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      isEditing: false,
      editedValue: null,
    };
  },
  computed: {
    displayValue() {
      if (this.isAttributeTypeDate) {
        return new Date(this.value || new Date()).toLocaleDateString();
      }
      if (this.isAttributeTypeCheckbox) {
        return this.value === 'false' ? false : this.value;
      }
      return this.value;
    },
    formattedValue() {
      return this.isAttributeTypeDate
        ? format(this.value ? new Date(this.value) : new Date(), DATE_FORMAT)
        : this.value;
    },
    listOptions() {
      return this.values.map((value, index) => ({
        id: index + 1,
        name: value,
      }));
    },

    arrayOptions() {
      return this.values;
    },

    selectedItem() {
      const id = this.values.indexOf(this.editedValue) + 1;
      return { id, name: this.editedValue };
    },
    isAttributeTypeCheckbox() {
      return this.attributeType === 'checkbox';
    },
    isAttributeTypeList() {
      return this.attributeType === 'list';
    },
    isAttributeTypeLink() {
      return this.attributeType === 'Link';
    },
    isAttributeTypeNumber() {
      return this.attributeType === 'Number';
    },
    isAttributeTypeDate() {
      return this.attributeType === 'Date';
    },

    isAttributeTypeString() {
      return this.attributeType === 'String';
    },
    isAttributeBoolean() {
      return this.attributeType === 'Bool';
    },

    isAttributeArray() {
      return this.attributeType === 'Array';
    },
    urlValue() {
      return isValidURL(this.value) ? this.value : '---';
    },
    notAttributeTypeCheckboxAndList() {
      return !this.isAttributeTypeCheckbox && !this.isAttributeTypeList;
    },
    inputType() {
      return this.isAttributeTypeLink ? 'url' : this.attributeType;
    },
    shouldShowErrorMessage() {
      return this.v$.editedValue.$error;
    },
    errorMessage() {
      if (this.v$.editedValue.url) {
        return this.$t('CUSTOM_ATTRIBUTES.VALIDATIONS.INVALID_URL');
      }
      return this.$t('CUSTOM_ATTRIBUTES.VALIDATIONS.REQUIRED');
    },
  },
  watch: {
    value() {
      this.isEditing = false;
      this.editedValue = this.formattedValue;
    },
  },
  created: function() {},

  validations() {
    if (this.isAttributeTypeLink) {
      return {
        editedValue: { required, url },
      };
    }
    return {
      editedValue: { required },
    };
  },
  mounted() {
    this.editedValue = this.formattedValue;
    emitter.on(BUS_EVENTS.FOCUS_CUSTOM_ATTRIBUTE, this.onFocusAttribute);
    // this.$emitter.on(BUS_EVENTS.FOCUS_CUSTOM_ATTRIBUTE, this.onFocusAttribute);
  },
  destroyed() {
    emitter.off(BUS_EVENTS.FOCUS_CUSTOM_ATTRIBUTE, this.onFocusAttribute);
    // this.$emitter.off(BUS_EVENTS.FOCUS_CUSTOM_ATTRIBUTE, this.onFocusAttribute);
  },
  methods: {
    onFocusAttribute(focusAttributeKey) {
      if (this.attributeKey === focusAttributeKey) {
        this.onEdit();
      }
    },
    focusInput() {
      if (this.$refs.inputfield) {
        this.$refs.inputfield.focus();
      }
    },
    onEdit() {
      this.isEditing = true;
      this.$nextTick(() => {
        this.focusInput();
      });
    },
    onUpdateListValue(value) {
      if (value) {
        this.editedValue = value.name;
        this.onUpdate();
      }
    },
    onUpdate() {
      const updatedValue =
        this.attributeType === 'date'
          ? parseISO(this.editedValue)
          : this.editedValue;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.isEditing = false;
      this.$emit('update', this.attributeKey, updatedValue);
    },
    onDelete() {
      this.isEditing = false;
      this.$emit('delete', this.attributeKey);
    },
    onCopy(value) {
      const valueFromatted =
        this.attributeType === 'Date'
          ? format(value && new Date(value), 'dd-MM-yyyy')
          : value;
      this.$emit('copy', valueFromatted);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-wrap {
  @apply flex items-center;
}

.checkbox {
  @apply my-0 mr-2 ml-0;
}

.attribute-name {
  @apply w-full text-slate-800 dark:text-slate-100;
  &.error {
    @apply text-red-400 dark:text-red-500;
  }
}

.edit-button {
  @apply hidden;
}

.value--view {
  @apply flex;

  &.is-editable:hover {
    .value {
      @apply bg-slate-50 dark:bg-slate-700 mb-0;
    }

    .edit-button {
      @apply block;
    }
  }
}

::v-deep {
  .selector-wrap {
    @apply m-0 top-1;
    .selector-name {
      @apply ml-0;
    }
  }

  .name {
    @apply ml-0;
  }
}
</style>
