<template>
  <div
    class="flex flex-col mb-2 p-4 border border-solid border-slate-75 dark:border-slate-700 overflow-hidden rounded-md flex-grow shadow-sm bg-white dark:bg-slate-900 text-slate-700 dark:text-slate-100"
  >
    <woot-message-editor
      v-model="noteContent"
      class="input--note"
      :placeholder="$t('NOTES.ADD.PLACEHOLDER')"
      :enable-suggestions="false"
    />
    <div class="flex justify-end w-full">
      <woot-button
        color-scheme="warning"
        :title="$t('NOTES.ADD.TITLE')"
        @click="onAdd"
      >
        {{ $t('NOTES.BLANC.UPDATE.BUTTON') }} (⌘⏎)
      </woot-button>
    </div>
  </div>
</template>

<script>
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import { hasPressedCommandAndEnter } from 'shared/helpers/KeyboardHelpers';
export default {
  components: {
    WootMessageEditor,
  },
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    noteContent: {
      get() {
        return this.contact.blanc_note;
      },
      set(value) {
        this.contact.blanc_note = value;
      },
    },
  },
  mounted() {
    document.addEventListener('keydown', this.onMetaEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onMetaEnter);
  },
  methods: {
    onMetaEnter(e) {
      if (hasPressedCommandAndEnter(e)) {
        e.preventDefault();
        this.onAdd();
      }
    },
    onAdd() {
      this.$emit('update-blanc-note', this.noteContent);
    },
  },
};
</script>

<style lang="scss" scoped>
body.dark {
  .input--note {
    &::v-deep .ProseMirror-menubar {
      padding: 0;
      margin-top: var(--space-minus-small);
    }

    &::v-deep .ProseMirror-woot-style {
      max-height: 22.5rem;
      > p:not(.empty-node) {
        @apply bg-green-400;
      }
    }
  }
}

.input--note {
  &::v-deep .ProseMirror-menubar {
    padding: 0;
    margin-top: var(--space-minus-small);
  }

  &::v-deep .ProseMirror-woot-style {
    max-height: 22.5rem;
    > p:not(.empty-node) {
      @apply bg-yellow-400;
    }
  }
}
</style>
