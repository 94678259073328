<script setup>
import { computed, ref } from 'vue';
import SidebarGroupLeaf from './SidebarGroupLeaf.vue';
import SidebarGroupSeparator from './SidebarGroupSeparator.vue';
import SidebarGroupEmptyLeaf from './SidebarGroupEmptyLeaf.vue';
import { useMapGetter, useStore } from 'dashboard/composables/store';
import { useUISettings } from 'dashboard/composables/useUISettings';

import { useSidebarContext } from './provider';
import { useEventListener } from '@vueuse/core';

const props = defineProps({
  id: { type: String, default: null },
  isExpanded: { type: Boolean, default: false },
  label: { type: String, required: true },
  icon: { type: [Object, String], required: true },
  children: { type: Array, default: undefined },
  activeChild: { type: Object, default: undefined },
});

const { isAllowed } = useSidebarContext();
const scrollableContainer = ref(null);
const isEditing = ref(false);
const isUpdating = ref(false);
const editingItems = ref(new Set());
const store = useStore();

const { isContactSidebarItemOpen } = useUISettings();

const currentAccountId = useMapGetter('getCurrentAccountId');
const currentUser = useMapGetter('getCurrentUser');

const currentAccount = computed(() => {
  return currentUser.value.accounts.find(
    account => account.id === currentAccountId.value
  );
});

const displayedKeysByLabel = {
  AGENTS: 'ui_agent_ids',
  CUSTOM_VIEWS_FOLDER: 'ui_dir_ids',
  INBOXES: 'ui_source_ids',
  LABELS: 'ui_category_ids',
  TEAMS: 'ui_team_ids',
};

const displayedItems = computed(() => {
  const key = displayedKeysByLabel[props.id];
  return currentAccount.value?.blanc_displayed_items[key] || [];
});

const accessibleItems = computed(() =>
  props.children
    .filter(child => isAllowed(child.to))
    .filter(
      child => !isEditing.value && displayedItems.value.includes(child.id)
    )
);

const hasAccessibleItems = computed(() => {
  if (isEditing.value) return true;

  if (props.children.length === 0) {
    // cases like segment, folder and labels where users can create new items
    return true;
  }

  return accessibleItems.value.length > 0;
});

const isScrollable = computed(() => {
  if (!isContactSidebarItemOpen(props.id)) return false;

  return accessibleItems.value.length > 7;
});

const scrollEnd = ref(false);

// set scrollEnd to true when the scroll reaches the end
useEventListener(scrollableContainer, 'scroll', () => {
  const { scrollHeight, scrollTop, clientHeight } = scrollableContainer.value;
  scrollEnd.value = scrollHeight - scrollTop === clientHeight;
});

const toggleEdit = () => {
  if (isEditing.value) {
    isEditing.value = false;
  } else {
    isEditing.value = true;
    editingItems.value = new Set(displayedItems.value);
  }
};

const onChange = e => {
  if (e.target.checked) {
    editingItems.value = editingItems.value.add(Number(e.target.value));
  } else {
    editingItems.value.delete(Number(e.target.value));
  }
};

const updateBlancDisplayedItems = items => {
  if (isUpdating.value) return;

  isUpdating.value = true;
  try {
    store.dispatch('updateBlancDisplayedItems', {
      ...items,
      account_id: currentAccountId.value,
    });
  } finally {
    isUpdating.value = false;
  }
};

const saveDisplayedItems = () => {
  const key = displayedKeysByLabel[props.id];
  updateBlancDisplayedItems({
    ...currentAccount.value.blanc_displayed_items,
    [key]: [...editingItems.value],
  });
  isEditing.value = false;
};
</script>

<template>
  <SidebarGroupSeparator
    v-if="hasAccessibleItems"
    v-show="isExpanded"
    :id
    :label
    :icon
    class="my-1"
    @toggle-edit="toggleEdit"
  />
  <ul class="m-0 list-none reset-base relative group">
    <!-- Each element has h-8, which is 32px, we will show 7 items with one hidden at the end,
    which is 14rem. Then we add 16px so that we have some text visible from the next item  -->
    <div
      v-if="isContactSidebarItemOpen(id) || isEditing"
      ref="scrollableContainer"
      :class="{
        'max-h-[calc(14rem+16px)] overflow-y-scroll no-scrollbar': isScrollable,
      }"
    >
      <div v-if="isEditing">
        <woot-button
          size="tiny"
          variant="clear"
          color-scheme="secondary"
          @click="isEditing = false"
        >
          {{ $t(`SIDEBAR.CANCEL`) }}
        </woot-button>
        <woot-button
          size="tiny"
          variant="clear"
          color-scheme="secondary"
          @click="saveDisplayedItems"
        >
          {{ $t(`SIDEBAR.SAVE`) }}
        </woot-button>
      </div>
      <template v-if="children.length">
        <SidebarGroupLeaf
          v-for="child in children"
          v-show="isExpanded || activeChild?.name === child.name"
          v-bind="child"
          :key="child.name"
          :active="activeChild?.name === child.name"
          :is-editing="isEditing"
          :displayed-items="displayedItems"
          :editing-items="editingItems"
          @change="onChange"
        />
      </template>
      <SidebarGroupEmptyLeaf v-else v-show="isExpanded" class="ml-3 rtl:mr-3" />
    </div>
    <div
      v-if="isScrollable && isExpanded"
      v-show="!scrollEnd"
      class="absolute bg-gradient-to-t from-n-solid-2 w-full h-12 to-transparent -bottom-1 pointer-events-none flex items-end justify-end px-2 animate-fade-in-up"
    >
      <svg
        width="16"
        height="24"
        viewBox="0 0 16 24"
        fill="none"
        class="text-n-slate-9 opacity-50 group-hover:opacity-100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 4L8 8L12 4"
          stroke="currentColor"
          opacity="0.5"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4 10L8 14L12 10"
          stroke="currentColor"
          opacity="0.75"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4 16L8 20L12 16"
          stroke="currentColor"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </ul>
</template>
