import { mapGetters } from 'vuex';
import { isValidURL } from '../helper/URLHelper';

export default {
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      accountId: 'getCurrentAccountId',
    }),
    attributes() {
      return this.$store.getters['attributes/getAttributesByModel'](
        this.attributeType
      );
    },
    customAttributes() {
      if (this.attributeType === 'conversation_attribute')
        return this.currentChat.custom_attributes || {};
      return this.contact.custom_attributes || {};
    },
    contactIdentifier() {
      return (
        this.currentChat.meta?.sender?.id ||
        this.$route.params.contactId ||
        this.contactId
      );
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactIdentifier);
    },
    conversationId() {
      return this.currentChat.id;
    },
    filteredAttributes() {
      const defaultAttributes = { ...this.customAttributes };
      delete defaultAttributes?.backend;
      return Object.keys(defaultAttributes).map(key => {
        const item = this.attributes.find(
          attribute => attribute.attribute_key === key,
        );
        if (item) {
          return {
            ...item,
            value: this.customAttributes[key],
          };
        }
        return {
          ...item,
          value: this.customAttributes[key],
          attribute_description: key,
          attribute_display_name: key,
          attribute_display_type: this.attributeDisplayType(
            this.customAttributes[key],
          ),
          attribute_key: key,
          attribute_model: this.attributeType,
          id: Math.random(),
        };
      });
    },

    backendAttributes() {
      if (this.customAttributes && this.customAttributes?.backend && Object.keys(this.customAttributes?.backend.length > 0)) {
        return Object.keys(this.customAttributes?.['backend']).map(key => {
          const backendAttrubutes = this.customAttributes?.['backend'];
          let attribute_values = [];
          if (backendAttrubutes[key]?.type === 'Array') {
            attribute_values = backendAttrubutes[key]?.value.map(item => {
                return Object.keys(item).map(keyArray => {
                  return {
                    value: item[keyArray]?.value,
                    attribute_description: keyArray,
                    attribute_display_name: item[keyArray]?.label,
                    attribute_display_type: item[keyArray]?.type,
                    attribute_key: keyArray,
                    attribute_model: this.attributeType,
                    id: Math.random(),
                  };
                });
              },
            );
          }
          return {
            value: backendAttrubutes[key]?.value,
            attribute_description: key,
            attribute_display_name: backendAttrubutes[key]?.label,
            attribute_display_type: backendAttrubutes[key]?.type,
            attribute_key: key,
            attribute_values: attribute_values,
            attribute_model: this.attributeType,
            id: Math.random(),
          };
        });
      } else {
        return []
      }
    },
  },
  methods: {
    isAttributeNumber(attributeValue) {
      return (
        Number.isInteger(Number(attributeValue)) && Number(attributeValue) > 0
      );
    },
    attributeDisplayType(attributeValue) {
      if (this.isAttributeNumber(attributeValue)) {
        return 'number';
      }
      if (isValidURL(attributeValue)) {
        return 'link';
      }
      return 'text';
    },
  },
};
