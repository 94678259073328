<script setup>
import { isVNode, computed, toRefs } from 'vue';
import Icon from 'next/icon/Icon.vue';
import Policy from 'dashboard/components/policy.vue';
import { useSidebarContext } from './provider';

const props = defineProps({
  id: { type: [String, Number], default: undefined },
  counter: { type: Number, default: undefined },
  label: { type: String, required: true },
  to: { type: [String, Object], required: true },
  icon: { type: [String, Object], default: null },
  active: { type: Boolean, default: false },
  component: { type: Function, default: null },
  isEditing: { type: Boolean, default: false },
  editingItems: { type: Set, default: () => new Set() },
  displayedItems: { type: Array, default: () => [] },
});
defineEmits('change');

const { displayedItems, isEditing, editingItems } = toRefs(props);

const { resolvePermissions, resolveFeatureFlag } = useSidebarContext();

const shouldRenderComponent = computed(() => {
  return typeof props.component === 'function' || isVNode(props.component);
});

const isShowed = computed(() => {
  if (!props.id) return true;
  if (isEditing.value) return true;

  return displayedItems.value?.includes(props.id);
});
</script>

<!-- eslint-disable vue/no-root-v-if -->
<template>
  <Policy
    v-if="isShowed"
    :permissions="resolvePermissions(to)"
    :feature-flag="resolveFeatureFlag(to)"
    as="li"
    class="flex py-0.5 ltr:pl-3 rtl:pr-3 rtl:mr-3 ltr:ml-3 relative text-n-slate-11 child-item before:bg-n-slate-4 after:bg-transparent after:border-n-slate-4 before:left-0 rtl:before:right-0"
  >
    <input
      v-if="isEditing"
      :value="id"
      :checked="editingItems.has(id)"
      class="checkbx mr-2"
      type="checkbox"
      @change.prevent.stop="$emit('change', $event)"
    />
    <component
      :is="to ? 'router-link' : 'div'"
      :to="to"
      :title="label"
      class="flex h-8 items-center gap-2 px-2 py-1 rounded-lg max-w-[151px] hover:bg-gradient-to-r from-transparent via-n-slate-3/70 to-n-slate-3/70 group w-full"
      :class="{
        'n-blue-text bg-n-alpha-2 active': active,
      }"
    >
      <component
        :is="component"
        v-if="shouldRenderComponent"
        :label
        :icon
        :active
      />
      <template v-else>
        <Icon v-if="icon" :icon="icon" class="size-4 inline-block" />
        <div class="flex-1 truncate min-w-0">{{ label }}</div>
      </template>

      <span
        v-if="counter || counter === 0"
        class="text-slate-700 dark:text-slate-100 text-xs bg-slate-50 dark:bg-slate-700 rounded-full min-w-[18px] justify-center items-center flex text-xxs font-medium mx-1 py-0.5 px-1.5"
      >
        {{ counter }}
      </span>
    </component>
  </Policy>
</template>
