<script setup>
import Icon from 'next/icon/Icon.vue';
import { useUISettings } from 'dashboard/composables/useUISettings';

defineProps({
  id: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
});

defineEmits('toggleEdit');

const { toggleSidebarUIState } = useUISettings();
</script>

<template>
  <div
    class="flex items-center gap-2 px-2 py-1.5 rounded-lg h-8 text-n-slate-10 select-none"
    @click="toggleSidebarUIState(id)"
  >
    <Icon v-if="icon" :icon="icon" class="size-4" />
    <span class="text-sm font-medium leading-5 flex-grow">
      {{ label }}
    </span>
    <woot-button
      size="tiny"
      variant="smooth"
      color-scheme="secondary"
      icon="edit"
      @click.stop.prevent="$emit('toggleEdit')"
    />
  </div>
</template>
