<template>
  <div class="border border-slate-25 overflow-x-auto dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0">
    <form @submit.prevent="updateTeam">
      <label for="toggle-assignment-strategy" class="toggle-input-wrap">
        <input
          v-model="IsAssignmentEnabled"
          type="checkbox"
          name="toggle-assignment-strategy"
        />
        {{ $t('TEAMS_SETTINGS.ASSIGNMENT_STRATEGY.TOGGLE_AVAILABILITY') }}
      </label>
      <div v-if="IsAssignmentEnabled" class="filter-inputs">
        <select
          v-model="assignment_strategy"
          class="action__question full-width"
          @change="resetAction()"
        >
          <option
            v-for="attribute in AssignmentStrategies"
            :key="attribute.key"
            :value="attribute.key"
          >
            {{ attribute.value }}
          </option>
        </select>
      </div>
      <div class="modal-footer">
        <div class="medium-12 columns mt-10">
          <woot-submit-button
            :disabled="submitInProgress"
            :button-text="
              $t('TEAMS_SETTINGS.EDIT_FLOW.ASSIGNMENT_STRATEGY.BUTTON_TEXT')
            "
            :loading="submitInProgress"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import router from '../../../../index';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import WootSubmitButton from '../../../../../components/buttons/FormSubmitButton';
import { ASSIGNMENT_STRATEGIES } from '../helpers/constants';

export default {
  components: {
    WootSubmitButton,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      AssignmentStrategies: ASSIGNMENT_STRATEGIES,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
    teamId() {
      return this.$route.params.teamId;
    },
    currentTeam() {
      return this.$store.getters['teams/getTeam'](this.teamId);
    },
    assignment_strategy: {
      get() {
        if (!this.currentTeam.assignment_strategy) return null;
        return this.currentTeam.assignment_strategy;
      },
      set(value) {
        this.currentTeam.assignment_strategy = value;
      },
    },
    IsAssignmentEnabled: {
      get() {
        if (!this.currentTeam.assignment_enabled) return null;
        return this.currentTeam.assignment_enabled;
      },
      set(value) {
        this.currentTeam.assignment_enabled = value;
      },
    },
  },
  async mounted() {
    await this.$store.dispatch('teams/get');
    this.IsAssignmentEnabled = this.currentTeam.assignment_enabled;
  },
  methods: {
    async updateTeam() {
      try {
        const { teamId } = this;
        const payload = {
          id: this.teamId,
          assignment_strategy: this.currentTeam.assignment_strategy,
          assignment_enabled: this.currentTeam.assignment_enabled,
        };
        await this.$store.dispatch('teams/update', payload);
        router.replace({
          name: 'settings_teams_edit_finish',
          params: {
            page: 'edit',
            teamId,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    removeAction() {
      this.$emit('removeAction');
    },
    resetAction() {
      this.$emit('resetAction');
    },
  },
};
</script>
<style lang="scss" scoped>
.timezone-input-wrap {
  max-width: 60rem;

  &::v-deep .multiselect {
    margin-top: var(--space-small);
  }
}

.unavailable-input-wrap {
  max-width: 60rem;

  textarea {
    min-height: var(--space-jumbo);
    margin-top: var(--space-small);
  }
}

.business-hours-wrap {
  margin-bottom: var(--space-medium);
}

.richtext {
  padding: 0 var(--space-normal);
  border-radius: var(--border-radius-normal);
  border: 1px solid var(--color-border);
  margin: 0 0 var(--space-normal);
}
</style>
