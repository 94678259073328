<template>
  <div
      class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <page-header
        :header-title="headerTitle"
        :header-content="$t('TEAMS_SETTINGS.CREATE_FLOW.TEAM_CUSTOM_ATTRIBUTE_DEFINITION.DESC')"
    />

    <form class="mx-0 flex flex-wrap overflow-x-auto"
          @submit.prevent="addAttributes"
    >
      <div class="w-full">
        <custom-attribute-definition-selector
            v-if="showCustomAttributeList"
            :custom-attribute-list="customAttributeList"
            :selected-attributes="selectedAttributes"
            :update-selected-custom-attributes="updateSelectedCustomAttributes"
            :is-working="isCreating"
            :submit-button-text="
            $t('TEAMS_SETTINGS.CREATE_FLOW.TEAM_CUSTOM_ATTRIBUTE_DEFINITION.BUTTON_TEXT')
            "
        />
        <spinner v-else/>
      </div>
    </form>
  </div>


</template>

<script>
import { mapGetters } from 'vuex'
import Spinner from 'shared/components/Spinner.vue'
import alertMixin from 'shared/mixins/alertMixin'

import { useAlert } from 'dashboard/composables';
import router from '../../../../index'
import PageHeader from '../../SettingsSubPageHeader.vue'
import AgentSelector from '../AgentSelector.vue'
import CustomAttributeDefinitionSelector from '../CustomAttributeDefinitionSelector.vue'

export default {
  components: {
    CustomAttributeDefinitionSelector,
    Spinner,
    PageHeader,
    AgentSelector,
  },
  mixins: [alertMixin],

  props: {
    team: {
      type: Object,
      default: () => {},
    },
  },

  data () {
    return {
      selectedAgents: [],
      selectedAttributes: [],
      isCreating: false,
    }
  },

  computed: {
    ...mapGetters({
      attributeList: 'attributes/getAttributesByModel',
      uiFlags: 'attributes/getUIFlags',
    }),

    customAttributeList () {
      const contactAttributes = this.$store.getters['attributes/getAttributesByModel']('contact_attribute')
      const conversationAttributes = this.$store.getters['attributes/getAttributesByModel']('conversation_attribute')

      return [...contactAttributes, ...conversationAttributes]
    },

    teamId () {
      return this.$route.params.teamId
    },

    headerTitle () {
      return this.$t('TEAMS_SETTINGS.CREATE_FLOW.TEAM_CUSTOM_ATTRIBUTE_DEFINITION.TITLE', {
        teamName: this.currentTeam.name,
      })
    },
    currentTeam () {
      return this.$store.getters['teams/getTeam'](this.teamId)
    },
    showCustomAttributeList () {
      const { id } = this.currentTeam
      return id && !this.uiFlags.isFetching
    }
  },

  async mounted () {
    const { teamId } = this.$route.params
    try {
      await this.$store.dispatch('teams/get', {
        teamId,
      })

      const attributes = this.currentTeam.team_custom_attributes

      this.updateSelectedCustomAttributes(attributes)
    } catch {
      this.updateSelectedCustomAttributes([])
    }
  },

  methods: {
    updateSelectedCustomAttributes (newAttributeList) {
      this.selectedAttributes = [...newAttributeList]
    },

    async addAttributes () {
      this.isCreating = true
      const { teamId, selectedAttributes } = this

      try {
        await this.$store.dispatch('teams/update', {
          id: teamId,
          team: {
            custom_attribute_definition_ids: selectedAttributes,
          }
        })
        router.replace({
          name: 'settings_teams_add_agents',
          params: {
            page: 'new',
            teamId: teamId,
          },
        })
        this.$store.dispatch('teams/get')
      } catch (error) {
        this.useAlert(error.message)
      }
      this.isCreating = false
    },
  },
}
</script>
