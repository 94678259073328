<script>
export default {
  computed: {
    items() {
      const steps = [
          'WIZARD_CREATE',
          'WIZARD_CUSTOM_ATTRIBUTE_DEFINITION',
          'WIZARD_ADD_AGENTS',
          'WIZARD_WORKING_TIME',
          'WIZARD_ASSIGNMENT_STRATEGY',
          'WIZARD_FINISH'];

      const routes = {
        WIZARD_CREATE: 'settings_teams_new',
        WIZARD_CUSTOM_ATTRIBUTE_DEFINITION: 'settings_teams_add_custom_attributes_definition',
        WIZARD_ADD_AGENTS: 'settings_teams_add_agents',
        WIZARD_WORKING_TIME: 'settings_teams_add_working_time',
        WIZARD_ASSIGNMENT_STRATEGY: 'settings_teams_add_assignment_strategy',
        WIZARD_FINISH: 'settings_teams_finish',
      };

      return steps.map(step => {
        return {
          title: this.$t(`TEAMS_SETTINGS.CREATE_FLOW.${step}.TITLE`),
          body: this.$t(`TEAMS_SETTINGS.CREATE_FLOW.${step}.BODY`),
          route: routes[step],
        };
      });
    },
  },
};
</script>

<template>
  <div
    class="overflow-auto p-4 max-w-full my-auto flex flex-row flex-nowrap h-full bg-slate-25 dark:bg-slate-800"
  >
    <woot-wizard class="hidden md:block w-1/4" :items="items" />
    <router-view />
  </div>
</template>
