<template>
  <woot-modal :show.sync="show" :on-close="onCancel" modal-type="right-aligned">
    <div class="w-full overflow-auto h-full">
      <!--      h-auto overflow-auto flex flex-col-->
      <!--      -->
      <woot-modal-header
          :header-title="$t('CONTACT_PANEL.SIDEBAR_SECTIONS.CUSTOM_ATTRIBUTES')"
          :header-content="$t('CUSTOM_ATTRIBUTES.BUTTON')"
      />
      <div class="conversation_info">
        <AccordionItem
            :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONVERSATION_INFO')"
            :is-open="isContactSidebarItemOpen('is_conv_details_open')"
            compact
            @toggle="
                value => toggleSidebarUIState('is_conv_details_open', value)
              "
        >
          <conversation-info
              :conversation-attributes="conversationAdditionalAttributes"
              :contact-attributes="contactAdditionalAttributes"
              :attribute-modal="true"
              :team-custom-attributes="fullAttributes()"

          />
        </AccordionItem>
      </div>
      <div class="contact_attributes">
        <AccordionItem
            :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONTACT_ATTRIBUTES')"
            :is-open="isContactSidebarItemOpen('is_contact_attributes_open')"
            compact
            @toggle="
                  value =>
                    toggleSidebarUIState('is_contact_attributes_open', value)
                "
        >
          <CustomAttributes
              attribute-type="contact_attribute"
              attribute-class="conversation--attribute"
              attribute-from="conversation_contact_panel"
              class="even"
              :contact-id="contact.id"
              :attribute-modal="true"
              :team-custom-attributes="fullAttributes()"
          />
          <CustomAttributeSelector
              attribute-type="contact_attribute"
              :contact-id="contact.id"
              :attribute-modal="true"
              :team-custom-attributes="fullAttributes()"
          />

        </AccordionItem>
      </div>
      <div class="resolve-actions relative flex items-center justify-end mt-10">
        <div class="button-group">
          <woot-button
              v-if="true"
              class-names="resolve"
              color-scheme="success"
              icon="checkmark"
              emoji="✅"
              @click="onSuccess"
          >
            {{ $t('CONVERSATION_CUSTOM_ATTRIBUTES.SUBMIT_MODAL') }}
          </woot-button>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import attributeMixin from '../mixins/attributeMixin'
import AccordionItem from './Accordion/AccordionItem.vue'
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue'
import { useUISettings } from 'dashboard/composables/useUISettings'
import { useAlert } from 'dashboard/composables'

import CustomAttributeSelector
  from '../routes/dashboard/conversation/customAttributes/CustomAttributeSelector.vue'
import CustomAttributes
  from '../routes/dashboard/conversation/customAttributes/CustomAttributes.vue'
import ConversationInfo from '../routes/dashboard/conversation/ConversationInfo.vue'

export default {
  components: {
    ConversationInfo,
    CustomAttributes, CustomAttributeSelector,
    AccordionItem,
    WootDropdownMenu,
  },
  mixins: [attributeMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  setup () {
    const {
      updateUISettings,
      isContactSidebarItemOpen,
      contactSidebarItemsOrder,
      toggleSidebarUIState,
    } = useUISettings()

    return {
      updateUISettings,
      isContactSidebarItemOpen,
      contactSidebarItemsOrder,
      toggleSidebarUIState,
    }
  },

  data () {
    return {
      teamCustomAttributes: [],
    }
  },

  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      uiFlags: 'inboxAssignableAgents/getUIFlags',
    }),
    conversationAdditionalAttributes () {
      return this.currentConversationMetaData.additional_attributes || {}
    },
    channelType () {
      return this.currentChat.meta?.channel
    },
    contact () {
      return this.$store.getters['contacts/getContact'](this.contactId)
    },
    contactAdditionalAttributes () {
      return this.contact.additional_attributes || {}
    },
    contactId () {
      return this.currentChat.meta?.sender?.id
    },
    currentConversationMetaData () {
      return this.$store.getters[
          'conversationMetadata/getConversationMetadata'
          ](this.conversationId)
    },
    hasContactAttributes () {
      const { custom_attributes: customAttributes } = this.contact
      return customAttributes && Object.keys(customAttributes).length
    },
    requiredCustomAttributeList () {
      const contactAttributes = this.$store.getters['attributes/getAttributesByModel']('contact_attribute')
      const conversationAttributes = this.$store.getters['attributes/getAttributesByModel']('conversation_attribute')

      return [...contactAttributes, ...conversationAttributes]
          .filter(item => item.attribute_required === true)
          .map(item => item.id)
    },
  },
  watch: {
    conversationId (newConversationId, prevConversationId) {
      if (newConversationId && newConversationId !== prevConversationId) {
        this.getContactDetails()
      }
    },
    contactId () {
      this.getContactDetails()
    },
  },
  mounted () {
    this.getContactDetails()
    this.$store.dispatch('attributes/get', 0)
    this.teamCustomAttributes = (this.currentChat.meta?.team?.team_custom_attributes) || []
  },
  methods: {
    onCancel () {
      this.$emit('close')
    },
    onSuccess () {
      this.$emit('success')
    },
    getContactDetails () {
      if (this.contactId) {
        this.$store.dispatch('contacts/show', { id: this.contactId })
      }
    },
    getAttributesByModel () {
      if (this.contactId) {
        this.$store.dispatch('contacts/show', { id: this.contactId })
      }
    },
    fullAttributes () {
      return this.teamCustomAttributes.concat(this.requiredCustomAttributeList)
    }
  },
}
</script>
