<template>
  <div>
    <div class="add-agents__header"/>
    <table class="woot-table">
      <thead>
        <tr>
          <td class="ltr:pl-2.5 rtl:pr-2.5">
            <div class="flex items-center">
              <input
                  name="select-all-custom-attributes"
                  type="checkbox"
                  :checked="allCustomAttributesSelected ? 'checked' : ''"
                  :title="$t('TEAMS_SETTINGS.CUSTOM_ATTRIBUTE_DEFINITION.SELECT_ALL')"
                  @click.self="selectAllCustomAttributes"
              />
            </div>
          </td>
          <td class="text-slate-800 dark:text-slate-100 ltr:pl-2.5 rtl:pr-2.5">
            {{ $t('TEAMS_SETTINGS.CUSTOM_ATTRIBUTE_DEFINITION.TITLE') }}
          </td>
          <td class="text-slate-800 dark:text-slate-100 ltr:pl-2.5 rtl:pr-2.5">
            {{ $t('TEAMS_SETTINGS.CUSTOM_ATTRIBUTE_DEFINITION.DESC') }}
          </td>
          <td class="text-slate-800 dark:text-slate-100 ltr:pl-2.5 rtl:pr-2.5">
            {{ $t('TEAMS_SETTINGS.CUSTOM_ATTRIBUTE_DEFINITION.MODEL') }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
            v-for="customAttribute in customAttributeList"
            :key="customAttribute.id"
            :class="attributeRowClass(customAttribute.id)"
        >
          <td class="w-12">
            <div class="flex items-center">
              <input
                  type="checkbox"
                  :checked="isAttributeSelected(customAttribute.id)"
                  @click.self="() => handleSelectAttribute(customAttribute.id)"
              />
            </div>
          </td>
          <td>
            <div class="flex items-center gap-2">
              <thumbnail
                  :src="customAttribute.thumbnail"
                  size="24px"
                  :username="customAttribute.name"
                  :status="customAttribute.name"
              />
              <h4 class="text-base mb-0 text-slate-800 dark:text-slate-100">
                {{ customAttribute.attribute_display_name }}
              </h4>
            </div>
          </td>
          <td>
            {{ customAttribute.attribute_description || '---' }}
          </td>
          <td>
            {{ $t(translateModel(customAttribute.attribute_model)) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex items-center justify-between mt-2">
      <p>
        {{
          $t('TEAMS_SETTINGS.CUSTOM_ATTRIBUTE_DEFINITION.SELECTED_COUNT', {
            selected: selectedCustomAttributeCount,
            total: totalCustomAttributeCount,
          })
        }}
      </p>
      <woot-submit-button
          :button-text="submitButtonText"
          :loading="isWorking"
          :disabled="isWorking"
      />
    </div>
  </div>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue'

export default {
  components: {
    Thumbnail,
  },
  props: {
    customAttributeList: {
      type: Array,
      default: () => [],
    },
    selectedAttributes: {
      type: Array,
      default: () => [],
    },
    updateSelectedCustomAttributes: {
      type: Function,
      default: () => {},
    },
    isWorking: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  data () {
    return {}
  },
  computed: {
    selectedCustomAttributeCount () {
      return this.selectedAttributes.length
    },

    totalCustomAttributeCount() {
      return this.customAttributeList.length
    },

    allCustomAttributesSelected () {
      return this.selectedAttributes.length === this.customAttributeList.length
    },
  },

  methods: {
    isAttributeSelected (attributeId) {
      return this.selectedAttributes.includes(attributeId)
    },

    handleSelectAttribute (attributeId) {
      const shouldRemove = this.isAttributeSelected(attributeId)

      let result = []
      if (shouldRemove) {
        result = this.selectedAttributes.filter(item => item !== attributeId)
      } else {
        result = [...this.selectedAttributes, attributeId]
      }

      this.updateSelectedCustomAttributes(result)
    },

    selectAllCustomAttributes () {
      const result = this.customAttributeList.map(item => item.id)
      this.updateSelectedCustomAttributes(result)
    },

    attributeRowClass (attributeId) {
      return { 'is-active': this.isAttributeSelected(attributeId) }
    },

    translateModel (modelName) {
      if (modelName === 'conversation_attribute') {
        return 'TEAMS_SETTINGS.CUSTOM_ATTRIBUTE_DEFINITION.CONVERSATION'
      } else {
        return 'TEAMS_SETTINGS.CUSTOM_ATTRIBUTE_DEFINITION.CONTACT'
      }
    }
  },
}
</script>

<style scoped>
input {
  @apply mb-0;
}
</style>
