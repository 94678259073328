<script>
import { required, minLength, maxLength, numeric } from '@vuelidate/validators';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import { mapGetters } from 'vuex';
import FormInput from 'dashboard/components/widgets/forms/Input.vue';
import Auth from 'dashboard/api/auth';
import SubmitButton from 'dashboard/components/buttons/FormSubmitButton.vue';
import { useAlert } from 'dashboard/composables';
import { useVuelidate } from '@vuelidate/core';

export default {
  components: { FormInput, SubmitButton },
  mixins: [globalConfigMixin],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      credentials: { code: '' },
      otpApi: {
        message: '',
        isLoading: false,
      },
      error: '',
    };
  },
  computed: {
    ...mapGetters({}),
    code() {
      return this.credentials.code;
    },
  },
  validations: {
    credentials: {
      code: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    },
  },
  methods: {
    onChangeCode(val) {
      this.v$.credentials.code.$touch();
      const numbers = val.match(/\d+/g) || [];
      this.credentials.code = numbers.join('').slice(0, 6);
      this.$refs.inputRef.$refs.inputRef.value = numbers.join('').slice(0, 6);
    },
    logout() {
      Auth.logout();
    },
    showAlert(message, action) {
      this.otpApi.isLoading = false;
      useAlert(message, action);
    },
    submit() {
      this.otpApi.isLoading = true;
      Auth.verifyOtp(this.credentials)
        .then(() => {
          let successMessage = this.$t('OTP.API.SUCCESS_MESSAGE');
          this.showAlert(successMessage);
          window.location = '/';
        })
        .catch(error => {
          let errorMessage = this.$t('OTP.API.ERROR_MESSAGE');
          if (error?.response?.data?.message) {
            errorMessage = error.response.data.message;
          }
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>

<template>
  <div
    class="flex flex-col bg-woot-25 min-h-full w-full py-12 sm:px-6 lg:px-8 justify-center dark:bg-slate-900"
  >
    <form
      class="sm:mx-auto sm:w-full sm:max-w-lg bg-white dark:bg-slate-800 p-11 shadow sm:shadow-lg sm:rounded-lg"
      @submit.prevent="submit"
    >
      <h1
        class="mb-1 text-left text-2xl font-medium tracking-tight text-slate-900 dark:text-white"
      >
        {{ $t('OTP.TITLE') }}
      </h1>

      <div>
        <FormInput
          ref="inputRef"
          v-model="credentials.code"
          name="otp_code"
          :has-error="v$.credentials.code.$error"
          :error-message="$t('OTP.CODE.ERROR')"
          :placeholder="$t('OTP.CODE.PLACEHOLDER')"
          @update:model-value="onChangeCode"
        />
        <SubmitButton
          :disabled="v$.credentials.code.$invalid || otpApi.isLoading"
          :button-text="$t('OTP.SUBMIT')"
          :loading="otpApi.isLoading"
        />
      </div>
      <p class="text-sm text-slate-600 dark:text-woot-50 mt-4 -mb-1">
        {{ $t('OTP.GO_BACK_TO_LOGIN') }}
        <a class="text-link" href="#" @click="logout">
          {{ $t('COMMON.CLICK_HERE') }}.
        </a>
      </p>
    </form>
  </div>
</template>
