<script>
import { mapGetters } from 'vuex';

import adminMixin from '../../../mixins/isAdmin';
import {
  getInboxClassByType,
  getInboxWarningIconClass,
} from 'dashboard/helper/inbox';

import SecondaryChildNavItem from './SecondaryChildNavItem';
import {
  isOnMentionsView,
  isOnUnattendedView,
} from '../../../store/modules/conversations/helpers/actionHelpers';
import AccordionItem from '../../Accordion/AccordionItem.vue';

import uiSettingsMixin from 'dashboard/mixins/uiSettings';

export default {
  components: { SecondaryChildNavItem, AccordionItem },
  mixins: [adminMixin, uiSettingsMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isEditing: false,
      editingItems: new Set(),
    };
  },
  computed: {
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      globalConfig: 'globalConfig/get',
      currentUser: 'getCurrentUser',
    }),
    currentAccount() {
      return this.currentUser.accounts.find(
        account => account.id === this.accountId
      );
    },
    isActiveView() {
      return this.computedClass.includes('active-view');
    },
    hasSubMenu() {
      return !!this.menuItem.children;
    },
    isMenuItemVisible() {
      if (this.menuItem.globalConfigFlag) {
        return !!this.globalConfig[this.menuItem.globalConfigFlag];
      }
      if (this.menuItem.featureFlag) {
        return this.isFeatureEnabledonAccount(
          this.accountId,
          this.menuItem.featureFlag
        );
      }
      return true;
    },
    isAllConversations() {
      return (
        this.$store.state.route.name === 'inbox_conversation' &&
        this.menuItem.toStateName === 'home'
      );
    },
    isMentions() {
      return (
        isOnMentionsView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_mentions'
      );
    },
    isUnattended() {
      return (
        isOnUnattendedView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_unattended'
      );
    },
    isTeamsSettings() {
      return (
        this.$store.state.route.name === 'settings_teams_edit' &&
        this.menuItem.toStateName === 'settings_teams_list'
      );
    },
    isInboxSettings() {
      return (
        this.$store.state.route.name === 'settings_inbox_show' &&
        this.menuItem.toStateName === 'settings_inbox_list'
      );
    },
    isIntegrationsSettings() {
      return (
        this.$store.state.route.name === 'settings_integrations_webhook' &&
        this.menuItem.toStateName === 'settings_integrations'
      );
    },
    isApplicationsSettings() {
      return (
        this.$store.state.route.name === 'settings_applications_integration' &&
        this.menuItem.toStateName === 'settings_applications'
      );
    },
    isCurrentRoute() {
      return this.$store.state.route.name.includes(this.menuItem.toStateName);
    },

    isTeamMenu() {
      return this.menuItem?.key === 'team';
    },

    computedClass() {
      // If active inbox is present, do not highlight conversations
      if (this.activeInbox) return ' ';
      if (
        this.isAllConversations ||
        this.isMentions ||
        this.isUnattended ||
        this.isCurrentRoute
      ) {
        return 'bg-woot-25 dark:bg-slate-800 text-woot-500 dark:text-woot-500 hover:text-woot-500 dark:hover:text-woot-500 active-view';
      }
      if (this.hasSubMenu) {
        if (
          this.isTeamsSettings ||
          this.isInboxSettings ||
          this.isIntegrationsSettings ||
          this.isApplicationsSettings
        ) {
          return 'bg-woot-25 dark:bg-slate-800 text-woot-500 dark:text-woot-500 hover:text-woot-500 dark:hover:text-woot-500 active-view';
        }
        return 'hover:text-slate-700 dark:hover:text-slate-100';
      }

      return 'hover:text-slate-700 dark:hover:text-slate-100';
    },

    displayedItems() {
      const key = this.displayedKeysByLabel()[this.menuItem.label];
      return this.currentAccount.blanc_displayed_items[key] || [];
    },
  },
  methods: {
    displayedKeysByLabel() {
      return {
        AGENTS: 'ui_agent_ids',
        CUSTOM_VIEWS_FOLDER: 'ui_dir_ids',
        INBOXES: 'ui_source_ids',
        LABELS: 'ui_category_ids',
        TEAMS: 'ui_team_ids',
      };
    },
    updateBlancDisplayedItems(items) {
      if (this.isUpdating) return;

      this.isUpdating = true;
      try {
        this.$store.dispatch('updateBlancDisplayedItems', {
          ...items,
          account_id: this.accountId,
        });
      } finally {
        this.isUpdating = false;
      }
    },
    saveDisplayedItems() {
      const key = this.displayedKeysByLabel()[this.menuItem.label];
      this.updateBlancDisplayedItems({
        ...this.currentAccount.blanc_displayed_items,
        [key]: [...this.editingItems],
      });
      this.isEditing = false;
    },
    restoreDisplayedItems() {
      this.isEditing = false;
    },
    computedInboxClass(child) {
      const { type, phoneNumber } = child;
      if (!type) return '';
      const classByType = getInboxClassByType(type, phoneNumber);
      return classByType;
    },
    computedInboxErrorClass(child) {
      const { type, reauthorizationRequired } = child;
      if (!type) return '';
      const warningClass = getInboxWarningIconClass(
        type,
        reauthorizationRequired
      );
      return warningClass;
    },
    newLinkClick(e, navigate) {
      if (this.menuItem.newLinkRouteName) {
        navigate(e);
      } else if (this.menuItem.showModalForNewItem) {
        if (this.menuItem.modalName === 'AddLabel') {
          e.preventDefault();
          this.$emit('add-label');
        }
      }
    },
    showItem(item) {
      return this.isAdmin && !!item.newLink && item?.newLink;
    },
    onClickOpen() {
      this.$emit('open');
    },

    showCommandsPopover() {
      if (this.isTeamMenu) {
        const chatWrapper = document.querySelector('.chat-list-wrapper');
        const wrapperApp = document.querySelector('.app-wrapper');

        if (chatWrapper && wrapperApp) {
          const scroll = chatWrapper.scrollTop;
          chatWrapper.style.overflowY = 'hidden';
          chatWrapper.style.top = `-${scroll}px`;

          wrapperApp.style.overflow = 'hidden';
          chatWrapper.style.overflow = 'visible';
        }
      }
    },
    hideCommandsPopover() {
      const chatWrapper = document.querySelector('.chat-list-wrapper');
      const wrapperApp = document.querySelector('.app-wrapper');
      const currentScrollY = window.scrollY;

      if (chatWrapper && wrapperApp) {
        chatWrapper.style.top = `-${currentScrollY}px`;
        wrapperApp.style.overflow = '';
        chatWrapper.style.overflow = '';
      }
    },
    toggleEdit() {
      if (this.isEditing) {
        this.isEditing = false;
        this.restoreDisplayedItems();
      } else {
        this.isEditing = true;
        this.editingItems = new Set(this.displayedItems);
      }
    },
    onChange(e) {
      if (e.target.checked) {
        this.editingItems.add(Number(e.target.value));
      } else {
        this.editingItems.delete(Number(e.target.value));
      }
    },
  },
};
</script>

<template>
  <li>
    <AccordionItem
      v-show="isMenuItemVisible"
      :icon="menuItem.icon"
      :title="$t(`SIDEBAR.${menuItem.label}`)"
      :is-open="isContactSidebarItemOpen(menuItem.label) || isEditing"
      @toggle="value => toggleSidebarUIState(menuItem.label, value)"
    >
      <template #button>
        <woot-button
          size="tiny"
          variant="smooth"
          color-scheme="secondary"
          icon="edit"
          @click.stop.prevent="toggleEdit"
        />
      </template>
      <ul
        class="list-none ml-0 mb-0"
        @mouseenter="showCommandsPopover"
        @mouseleave="hideCommandsPopover"
      >
        <div v-if="isEditing">
          <woot-button
            size="tiny"
            variant="clear"
            color-scheme="secondary"
            @click="restoreDisplayedItems"
          >
            {{ $t(`SIDEBAR.CANCEL`) }}
          </woot-button>
          <woot-button
            size="tiny"
            variant="clear"
            color-scheme="secondary"
            @click="saveDisplayedItems"
          >
            {{ $t(`SIDEBAR.SAVE`) }}
          </woot-button>
        </div>
        <SecondaryChildNavItem
          v-for="(child, index) in menuItem.children"
          :id="child.id"
          :key="index"
          :to="child.toState"
          :label="child.label"
          :email="child.email"
          :thumbnail="child.thumbnail"
          :label-color="child.color"
          :should-truncate="child.truncateLabel"
          :icon="computedInboxClass(child)"
          :warning-icon="computedInboxErrorClass(child)"
          :counter="child.counter"
          :is-team-menu="isTeamMenu"
          :is-editing="isEditing"
          :displayed-items="displayedItems"
          :editing-items="editingItems"
          @change="onChange"
        />
        <router-link
          v-if="showItem(menuItem)"
          v-slot="{ href, navigate }"
          :to="menuItem.toState"
          custom
        >
          <li class="pl-1">
            <a :href="href">
              <woot-button
                size="tiny"
                variant="clear"
                color-scheme="secondary"
                icon="add"
                @click="e => newLinkClick(e, navigate)"
              >
                {{ $t(`SIDEBAR.${menuItem.newLinkTag}`) }}
              </woot-button>
            </a>
          </li>
        </router-link>
      </ul>
    </AccordionItem>
  </li>
</template>
