<script>
import { mapGetters } from 'vuex';
import InboxDropdownItem from 'dashboard/components/widgets/InboxDropdownItem.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { ExceptionWithMessage } from 'shared/helpers/CustomErrors';
import { getInboxSource } from 'dashboard/helper/inbox';
import { required, minLength } from '@vuelidate/validators';
import inboxMixin from 'shared/mixins/inboxMixin';
import fileUploadMixin from 'dashboard/mixins/fileUploadMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import ConversationItem from 'dashboard/components/ConversationItem.vue';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';
import IntersectionObserver from 'dashboard/components/IntersectionObserver.vue';

export default {
  components: {
    InboxDropdownItem,
    DynamicScroller,
    DynamicScrollerItem,
    IntersectionObserver,
  },
  mixins: [alertMixin, uiSettingsMixin, inboxMixin, fileUploadMixin],
  provide() {
    return {
      selectConversation: this.selectConversation,
      deSelectConversation: this.deSelectConversation,
      assignAgent: this.identity,
      assignTeam: this.identity,
      assignLabels: this.identity,
      updateConversationStatus: this.identity,
      toggleContextMenu: this.identity,
      markAsUnread: this.identity,
      assignPriority: this.identity,
    };
  },
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      targetInbox: {},
      itemComponent: ConversationItem,
      selectedConversations: [],
      virtualListExtraProps: {
        isConversationSelected: this.isConversationSelected,
      },
      infiniteLoaderOptions: {
        root: this.$refs.conversationList,
        rootMargin: '100px 0px 100px 0px',
      },
    };
  },
  validations: {
    targetInbox: {
      required,
    },
    selectedConversations: {
      required,
      minLength: minLength(2),
    },
  },
  computed: {
    ...mapGetters({
      conversationsUiFlags: 'contactConversations/getUIFlags',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      chatListLoading: 'getChatListLoadingStatus',
    }),
    inboxes() {
      const inboxList = this.contact.contactableInboxes || [];
      return inboxList.map(inbox => ({
        ...inbox.inbox,
        sourceId: inbox.source_id,
      }));
    },
    inbox() {
      return this.targetInbox;
    },
    conversationList() {
      return this.$store.getters['contactConversations/getContactConversation'](
        this.contact.id
      ).filter(conv => {
        return conv.inbox_id === this.targetInbox.id;
      });
    },
  },
  watch: {
    targetInbox() {
      this.selectedConversations = [];
    },
  },
  methods: {
    identity() {},
    isConversationSelected(id) {
      return this.selectedConversations.includes(id);
    },
    selectConversation(conversationId) {
      this.selectedConversations.push(conversationId);
    },
    deSelectConversation(conversationId) {
      this.selectedConversations = this.selectedConversations.filter(
        item => item !== conversationId
      );
    },
    computedInboxSource(inbox) {
      if (!inbox.channel_type) return '';
      const classByType = getInboxSource(
        inbox.channel_type,
        inbox.phone_number,
        inbox
      );
      return classByType;
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
    onFormSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.mergeConversations();
    },
    async mergeConversations() {
      try {
        const data = await this.onSubmit({
          inbox_id: this.targetInbox.id,
          conversation_ids: this.selectedConversations,
        });
        const action = {
          type: 'link',
          to: `/app/accounts/${this.$route.params.accountId}/conversations/${data.id}`,
          message: this.$t('MERGE_CONVERSATION.FORM.GO_TO_CONVERSATION'),
        };
        this.onSuccess();
        useAlert(this.$t('MERGE_CONVERSATION.FORM.SUCCESS_MESSAGE'), action);
      } catch (error) {
        if (error instanceof ExceptionWithMessage) {
          useAlert(error.data);
        } else {
          useAlert(this.$t('MERGE_CONVERSATION.FORM.ERROR_MESSAGE'));
        }
      }
    },
  },
};
</script>

<template>
  <form
    class="conversation--form w-full min-h-[300px]"
    @submit.prevent="onFormSubmit"
  >
    <div>
      <div class="gap-2 flex flex-row">
        <div class="w-[100%]">
          <label>
            {{ $t('MERGE_CONVERSATION.FORM.INBOX.LABEL') }}
          </label>
          <div
            class="multiselect-wrap--small"
            :class="{ 'has-multi-select-error': v$.targetInbox.$error }"
          >
            <multiselect
              v-model="targetInbox"
              track-by="id"
              label="name"
              :placeholder="$t('FORMS.MULTISELECT.SELECT')"
              selected-label=""
              select-label=""
              deselect-label=""
              :max-height="160"
              :close-on-select="true"
              :options="[...inboxes]"
            >
              <template #singleLabel="{ option }">
                <InboxDropdownItem
                  v-if="option.name"
                  :name="option.name"
                  :inbox-identifier="computedInboxSource(option)"
                  :channel-type="option.channel_type"
                />
                <span v-else>
                  {{ $t('MERGE_CONVERSATION.FORM.INBOX.PLACEHOLDER') }}
                </span>
              </template>
              <template #option="{ option }">
                <InboxDropdownItem
                  :name="option.name"
                  :inbox-identifier="computedInboxSource(option)"
                  :channel-type="option.channel_type"
                />
              </template>
            </multiselect>
          </div>
          <label :class="{ error: v$.targetInbox.$error }">
            <span v-if="v$.targetInbox.$error" class="message">
              {{ $t('MERGE_CONVERSATION.FORM.INBOX.ERROR') }}
            </span>
          </label>
        </div>
      </div>
      <div class="w-full">
        <p class="w-full">Диалоги</p>
        <label :class="{ error: v$.selectedConversations.$error }">
          <span v-if="v$.selectedConversations.$error" class="message">
            {{ $t('MERGE_CONVERSATION.FORM.SELECTED_CONVERSATIONS.ERROR') }}
          </span>
        </label>
        <div
          class="conversations-list-wrap max-h-[540px] flex-basis-clamp flex-shrink-0 overflow-hidden flex flex-col border-r rtl:border-r-0 rtl:border-l border-slate-50 dark:border-slate-800/50"
        >
          <div ref="conversationList" class="conversations-list flex-1">
            <!-- <DynamicScroller
              ref="conversationVirtualList"
              data-key="id"
              :data-sources="conversationList"
              :data-component="itemComponent"
              :extra-props="virtualListExtraProps"
              class="w-full overflow-auto h-fll"
              footer-tag="div"
            /> -->
            <DynamicScroller
              ref="conversationDynamicScroller"
              :items="conversationList"
              :min-item-size="24"
              class="w-full h-full overflow-auto"
            >
              <template #default="{ item, index, active }">
                <DynamicScrollerItem
                  :item="item"
                  :active="active"
                  :data-index="index"
                  :size-dependencies="[
                    item.messages,
                    item.labels,
                    item.uuid,
                    item.inbox_id,
                  ]"
                >
                  <ConversationItem
                    :source="item"
                    :label="label"
                    :team-id="teamId"
                    :folders-id="foldersId"
                    :conversation-type="conversationType"
                    :show-assignee="showAssigneeInConversationCard"
                    @select-conversation="selectConversation"
                    @de-select-conversation="deSelectConversation"
                  />
                </DynamicScrollerItem>
              </template>
              <template #after>
                <div v-if="chatListLoading" class="text-center">
                  <span class="mt-4 mb-4 spinner" />
                </div>
                <p
                  v-else-if="showEndOfListMessage"
                  class="p-4 text-center text-slate-400 dark:text-slate-300"
                >
                  {{ $t('CHAT_LIST.EOF') }}
                </p>
                <IntersectionObserver
                  v-else
                  :options="intersectionObserverOptions"
                  @observed="loadMoreConversations"
                />
              </template>
            </DynamicScroller>

          </div>
          <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
            <button class="button clear" @click.prevent="onCancel">
              {{ $t('MERGE_CONVERSATION.FORM.CANCEL') }}
            </button>
            <woot-button
              type="submit"
              :is-loading="conversationsUiFlags.isFetching"
            >
              {{ $t('MERGE_CONVERSATION.FORM.SUBMIT') }}
            </woot-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<style scoped lang="scss">
.conversations-list-wrap {
  max-width: 100%;
  position: relative;
  &.hide {
    @apply hidden;
  }

  &.list--full-width {
    @apply basis-full;
  }
  &:hover {
    .resize-component {
      opacity: 0.5;
    }
  }
}

.conversations-list {
  @apply overflow-hidden hover:overflow-y-auto;
}

.load-more--button {
  @apply text-center rounded-none;
}

.conversation--form {
  @apply pt-4 px-8 pb-8;
}

.multiselect-wrap--small.has-multi-select-error {
  ::v-deep {
    .multiselect__tags {
      @apply border-red-500;
    }
  }
}

::v-deep {
  .mention--box {
    @apply left-0 m-auto right-0 top-auto h-fit;
  }
  .multiselect .multiselect__content .multiselect__option span {
    @apply inline-flex w-6 text-slate-600 dark:text-slate-400;
  }
  .multiselect .multiselect__content .multiselect__option {
    @apply py-0.5 px-1;
  }
}
</style>
